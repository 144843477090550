.helloParagraph {
    margin-right:1.5rem
}
.textOnImage {
    display: inline-flex;
    /* position: absolute; */
    position: relative;
    /* bottom: 2.75rem; */
    padding: 1rem;
    border-radius: 25%;
    align-items: center;
    color: black;
    font-weight: 700;
    /*text-shadow: 0px 0px 5px black;*/
}
.boxForImage{
    margin:1.25rem
}