@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

.noto {
    font-family: 'Noto Sans', sans-serif;
}

.appbar {
    color: #fff;
    background: #07477B;
    height: 100px;
    justify-content: center;
    align-items: center;
}
.awrlogo{
    width:111px
}

@media screen and (min-width:600px){
    .awrlogo{
        width:155px
    }
}