@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
.noto {
    font-family: 'Noto Sans', sans-serif !important;
}
.css-1d7i659-MuiStepLabel-label.Mui-active {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
}
.tabs{
    justify-content:center
}
.mainBox {
    background-color: #f6f7fb;
    box-shadow: 0px 0px 48px -13px rgba(0, 48, 96,0.45);
}
.imageBox {
    /*background: rgb(255,255,255);*/
    /*background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 42%, rgba(218,230,255,0.9360119047619048) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 42%, rgba(218,230,255,0.9360119047619048) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,1) 42%, rgba(218,230,255,0.9360119047619048) 100%);*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#dae6ff",GradientType=1);
    /*display: flex;*/
    flex-direction: column;
    
    align-items:center
}
.miniatureImage{
    width:100%;
   
}
.miniaturesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    padding: 1.5rem;
    max-height: 42vh;
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 4px 2px inset;
    border-radius: 20px;
    background-color: rgb(0 0 0 / 0.85%);
    border: 1px solid rgb(0 0 0 / 10%);
}
.cardVisualizer {
    /*max-height:100%;
    max-width:100%;*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center
}
.helloText {
    background-color: white;
    border-radius: 20%;
    padding: .5rem 1rem;
    text-shadow: 0 0 1px black;
    color: black;
}
.boxForImage {
    display: inline-flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width:100%;
    max-width: -webkit-fill-available
}
.textOnImage {
    display: inline-flex;
    /* position: absolute; */
    position: relative;
    /* bottom: 2.75rem; */
    padding: 1rem;
    border-radius: 25%;
    align-items: center;
    color: white;
    font-weight: 700;
    text-shadow: 0px 0px 5px black;
    
}
.platform{
    display:flex;
    flex-direction:column;
    margin:.25rem;
    align-items:center
}
.platformLogo{
    /*width:100%;*/
    height:100%
}
.qrImage{
    margin-left:3.5rem
}
.digitalOptions {
    display: flex;
    flex-direction: row;
    padding-top: 1.5rem;
    justify-content: space-evenly;
}
.selectBackground {
    text-align: center;
    margin: 1rem;
    padding: 1rem;
    font-family: 'Noto Sans', sans-serif;
}

.shareButtons{
    display:flex;
}
.footer {
    display:flex;
    width: 100%;
}
.bannerlogo {
    color: transparent;
    max-height: 95px;
    max-width: 240px;
    /* filter: drop-shadow(0px 0px 0px white); */
    padding: 1.25rem .175rem;
}
.imagefooter{
    height:auto
}
.selected{
    font-style: italic;
    text-transform:capitalize
}
.miniaturesContainer img:hover{
    cursor:pointer
}
.socialMediaTypo {
    font-size: inherit !important;
    padding: 0 8px
}
.selector{
    margin:.55rem
}
.viberLogo {
    transform:scale(0.56)
}
@media screen and (max-width:586px) {
    .bannerlogo {
        width:99px
    }
}